const MAX_ITEMS = 5

export const getPaginationRange = (current, max, limit = MAX_ITEMS) => {
  return Array.from(
    Array(Math.min(limit, max <= limit ? max : limit)).keys()
  ).map(
    i => i + (max - current < limit ? Math.max(max - (limit - 1), 1) : current)
  )
}

export const getValidUrl = url => {
  return url.startsWith('/') ? url : `/${url}`
}

export const deentitize = rawHTML => {
    if(!rawHTML) return;
    var ret = rawHTML.replace(/&gt;/g, '>');
    ret = ret.replace(/&lt;/g, '<');
    ret = ret.replace(/&quot;/g, '"');
    ret = ret.replace(/&apos;/g, "'");
    ret = ret.replace(/&amp;/g, '&');
    return ret;
};
