import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react'
import Icon from '../../images/icons.svg'
import SmallAvatar from '../../images/icon-avatar.svg'
import { Container, Row, Col } from 'react-bootstrap'
import CartQuickView from '../CartQuickView'
import { useStaticQuery, graphql, Link, navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import CategoryMagento from '../CategoryMagento/CategoryMagento'

const Header = ({ config }) => {
  const { token: cartToken, items: cartItems } = useSelector(
    state => state.cart
  )
  const token = useSelector(state => state.auth.token)
  const customerCategoryId = useSelector(state => state.auth.customerCategoryId)
  const authUser = useSelector(state => state.auth.user)
  const [show, setShow] = useState(false)
  const toggleSidebar = useCallback(() => setShow(value => !value))
  const [showAccountMenu, setShowAccountMenu] = useState(false)

  const menu = useRef()
  const menuSection = useRef()
  const menuArrow = useRef()
  const menuClosed = useRef()
  const menuToggle = useRef()
  const menuOverlay = useRef()
  const menuAccountRef = useRef()
  let subMenu

  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.has('q')) {
        setKeyword(searchParams.get('q'))
      }
    }
  }, [])

  // Trigger showSubmenu
  function triggerMenu(e) {
    if (!menu.current.classList.contains('active')) {
      return
    }
    if (e.target.closest('.menu-item-has-children')) {
      const hasChildren = e.target.closest('.menu-item-has-children')
      showSubMenu(hasChildren)
    }
  }

  // Show & Hide Toggle Menu Function
  function toggleMenu() {
    menu.current.classList.toggle('active')
    menuOverlay.current.classList.toggle('active')
  }

  // Show the Mobile Side Menu Function
  function showSubMenu(hasChildren) {
    subMenu = hasChildren.querySelector('.menu-subs')
    subMenu.classList.add('active')
    subMenu.style.animation = 'slideLeft 0.5s ease forwards'
    const menuTitle = hasChildren.querySelector('.parent-text').textContent
    document.querySelector('.menu-mobile-title').innerHTML = menuTitle
    document.querySelector('.menu-mobile-header').classList.add('active')
  }

  // Hide the Mobile Side Menu Function
  function hideSubMenu() {
    subMenu.style.animation = 'slideRight 0.5s ease forwards'
    setTimeout(() => {
      subMenu.classList.remove('active')
    }, 300)
    document.querySelector('.menu-mobile-title').innerHTML = ''
    document.querySelector('.menu-mobile-header').classList.remove('active')
  }

  const totalCartItems = useMemo(() => {
    if (!cartItems) return 0

    return cartItems.reduce((acc, item) => acc + item.quantity, 0)
  }, [cartItems])

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (
        showAccountMenu &&
        menuAccountRef &&
        menuAccountRef.current &&
        !menuAccountRef.current.contains(e.target)
      ) {
        setShowAccountMenu(false)
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [showAccountMenu])

  const siteQuery = useStaticQuery(graphql`
    {
      ss {
        readOneSiteConfig {
          adminEmail
          headScripts
          bodyEndScripts
          bodyStartScripts
          robotsTXT
          facebook
          instagram
          linkedIn
          youtube
          vimeo
          pinterest
        }
        readPages(
          filter: { parentID: { eq: "0" }, showInMenus: { eq: true } }
        ) {
          nodes {
            id
            menuTitle
            link
            children {
              nodes {
                id
                title
                showInMenus
                link
                menuTitle
                children {
                  nodes {
                    id
                    title
                    showInMenus
                    link
                    menuTitle
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const siteConfig = siteQuery.ss.readOneSiteConfig
  const headScripts = siteConfig
    ? siteConfig.headScripts.replace('<script>', '').replace('</script>', '')
    : null
  const menus = siteQuery.ss.readPages.nodes

  const loadData = async () => {
    // Wait for two second
    await new Promise(r => setTimeout(r, 1000))
    // Toggle loading state
    // Thien commented this line: TRIGGER ERROR HERE
    // setLoading(loading => !loading)
  }

  function handleSearch(event) {
    event.preventDefault()
    window.location = '/search/?q=' + keyword
  }

  const windowOrign =
    typeof window !== 'undefined' ? window.location.origin : ''

  const inputRef = useRef()
  const [showSearch, setShowSearch] = useState(false)

  const toggleSearch = () => {
    setShowSearch(current => !current)

    inputRef.current.focus()
  }

  const clearSearch = () => {
    setShowSearch(current => !current)

    inputRef.current.value = ''
    setKeyword('')
  }
  return (
    <>
      {/* {headScripts && (
        <Helmet>
          <script>{headScripts}</script>
        </Helmet>
      )} */}
      <header className="header">
        <Container>
          <div className="nav-wrapper">
            <div className="header-item-left">
              <Link to="/" className="brand">
                <svg focusable="false" width="140" height="40">
                  <title>Grocorp</title>
                  <use xlinkHref={`${Icon}#logo`}></use>
                </svg>
              </Link>
            </div>
            <div className="header-item-center">
              <div
                className="overlay"
                ref={menuOverlay}
                onClick={toggleMenu}
                aria-hidden="true"
              ></div>
              <nav className="menu" id="menu" ref={menu}>
                <div className="menu-mobile-header">
                  <button
                    type="button"
                    className="menu-mobile-arrow"
                    ref={menuArrow}
                    onClick={hideSubMenu}
                    aria-hidden="true"
                  >
                    Back
                  </button>
                  <div className="menu-mobile-title text-md text-truncate px-2"></div>
                  <button
                    type="button"
                    className="menu-mobile-close"
                    ref={menuClosed}
                    onClick={toggleMenu}
                    aria-hidden="true"
                  >
                    Close
                  </button>
                </div>
                <ul
                  className="menu-section"
                  ref={menuSection}
                  onClick={triggerMenu}
                  aria-hidden="true"
                >
                  <CategoryMagento />
                  {menus.length > 0 && (
                    <>
                      {menus.map(menu => {
                        let children = menu.children.nodes
                        if (children.length > 0) {
                          return (
                            <li
                              className="menu-item-has-children"
                              key={`menu-${menu.id}`}
                            >
                              <Link to={menu.link} className="parent-text">
                                {menu.menuTitle}
                              </Link>
                              <div className="menu-subs menu-column-1">
                                <ul className="inside-list">
                                  {children.map(submenu => {
                                    let childrenLevel2 = submenu.children.nodes
                                    childrenLevel2 = []
                                    if (childrenLevel2.length > 0) {
                                      return (
                                        <li
                                          className="menu-item-has-children"
                                          key={`menu-level1-${submenu.id}`}
                                        >
                                          <Link
                                            to={submenu.link}
                                            className="parent-text"
                                          >
                                            {submenu.menuTitle}
                                          </Link>
                                          <div className="menu-subs menu-column-1">
                                            <ul className="inside-list">
                                              {childrenLevel2.map(
                                                submenuLevel2 => {
                                                  return (
                                                    <li
                                                      key={`menu-level2-${submenuLevel2.id}`}
                                                    >
                                                      <Link
                                                        to={submenuLevel2.link}
                                                      >
                                                        {
                                                          submenuLevel2.menuTitle
                                                        }
                                                      </Link>
                                                    </li>
                                                  )
                                                }
                                              )}
                                            </ul>
                                          </div>
                                        </li>
                                      )
                                    } else {
                                      return (
                                        <li
                                          className="menu-item"
                                          key={`menu-level1-${submenu.id}`}
                                        >
                                          <Link to={submenu.link}>
                                            {submenu.menuTitle}
                                          </Link>
                                        </li>
                                      )
                                    }
                                  })}
                                </ul>
                              </div>
                            </li>
                          )
                        } else {
                          return (
                            <li className="menu-item" key={`menu-${menu.id}`}>
                              <Link to={menu.link}>{menu.menuTitle}</Link>
                            </li>
                          )
                        }
                      })}
                    </>
                  )}
                </ul>
              </nav>
            </div>
            <div className="header-item-right">
              <form className={`nav-searchbox${showSearch ? ' show' : ''}`}>
                <input
                  className="form-control text-sm"
                  placeholder="Search for..."
                  onChange={e => {
                    setKeyword(e.target.value)
                  }}
                  ref={inputRef}
                  value={keyword}
                />
                <button
                  type="submit"
                  className="search-btn"
                  onClick={handleSearch}
                >
                  <svg width="20" height="20">
                    <use xlinkHref={`${Icon}#icon-search`}></use>
                  </svg>
                </button>
                <span
                  className="btn-close btn-reset"
                  aria-label="Close"
                  onClick={clearSearch}
                ></span>
              </form>
              {!!token && !!authUser ? (
                <div className="navbar navbar-expand" ref={menuAccountRef}>
                  <div className="collapse navbar-collapse">
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item dropdown">
                        <a
                          className="menu-icon nav-link dropdown-toggle"
                          href="#"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={e => {
                            e.preventDefault()
                            setShowAccountMenu(_ => !_)
                          }}
                        >
                          <div className="avt-sm">
                            <img src={SmallAvatar} />
                          </div>
                          <span className="text-truncate">
                            {authUser.firstName}
                          </span>
                        </a>
                        <div
                          className={`dropdown-menu${
                            showAccountMenu ? ' show' : ''
                          }`}
                        >
                          <a
                            className="dropdown-item"
                            href={`${process.env.GATSBY_CMS_URL}/headless/sso?token=${token}&loginUrl=${windowOrign}/login`}
                          >
                            My Account
                          </a>
                          {!!customerCategoryId && (
                            <a
                              className="dropdown-item"
                              href={`${process.env.GATSBY_CMS_URL}/headless/sso?token=${token}&returnUrl=${process.env.GATSBY_CMS_URL}/my-products`}
                            >
                              My Products
                            </a>
                          )}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <Link to="/login" className="menu-icon">
                  <svg width="22" height="22">
                    <use xlinkHref={`${Icon}#icon-circle-user`}></use>
                  </svg>
                </Link>
              )}
              <Link to="/wishlist" className="menu-icon">
                <svg width="22" height="22">
                  <use xlinkHref={`${Icon}#icon-heart`}></use>
                </svg>
              </Link>

              {!!token ? (
                <a
                  className="menu-icon btn-reset"
                  href={`${process.env.GATSBY_CMS_URL}/headless/sso?token=${token}&returnUrl=${process.env.GATSBY_CMS_URL}/checkout/cart`}
                >
                  <svg width="22" height="22">
                    <use xlinkHref={`${Icon}#icon-bag`}></use>
                  </svg>
                  {totalCartItems > 0 && <span>({totalCartItems})</span>}
                </a>
              ) : (
                <a
                  className="menu-icon btn-reset"
                  href={`${process.env.GATSBY_CMS_URL}/headless/cart?token=${cartToken}`}
                >
                  <svg width="22" height="22">
                    <use xlinkHref={`${Icon}#icon-bag`}></use>
                  </svg>
                  {totalCartItems > 0 && <span>({totalCartItems})</span>}
                </a>
              )}
              <button
                className="menu-icon btn-reset d-flex d-lg-none"
                onClick={toggleSearch}
              >
                <svg width="20" height="20">
                  <use xlinkHref={`${Icon}#icon-search`}></use>
                </svg>
              </button>

              <button
                type="button"
                className="menu-mobile-toggle"
                ref={menuToggle}
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </Container>
        <CartQuickView show={show} handleClose={setShow}></CartQuickView>
      </header>
    </>
  )
}
export default Header
