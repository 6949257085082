import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
} from 'react-bootstrap'
import Icon from '../../images/icons.svg'
import PaymentIcon from '../../images/payment.png'
import { useStaticQuery, graphql, Link } from 'gatsby'

const Footer = () => {
  const footerQuery = useStaticQuery(graphql`
    {
      ss {
        readOneSiteConfig {
          footerColumn1Title
          footerColumn2Title
          footerColumn3Title
          salesEmail
          phoneNumber
          phoneNumberText
          address
          contactUsLink {
            link
          }
          requestCallbackLink {
            link
          }
        }
        readFooterMenus {
          nodes {
            position
            link {
              title
              url
              type
              siteTree {
                link
              }
              openInNewWindow
              file {
                absoluteLink
              }
            }
          }
        }
      }
    }
  `)
  const [footerMenus, setFooterMenus] = useState(null)
  const [siteConfig, setSiteConfig] = useState(null)

  useEffect(() => {
    setFooterMenus(footerQuery.ss.readFooterMenus.nodes)
    setSiteConfig(footerQuery.ss.readOneSiteConfig)
  })
  if (footerMenus && footerMenus.length > 0) {
    footerMenus.some(function (footerMenu) {
      let type = footerMenu.link.type
      switch (type) {
        case 'SiteTree':
          footerMenu.linkURL = footerMenu.link.siteTree.link
          break
        case 'URL':
          footerMenu.linkURL = footerMenu.link.url
          break
        case 'File':
          footerMenu.linkURL = footerMenu.link.file.absoluteLink
          break
        default:
          break
      }
    })
  }

  const column1Menus = footerMenus
    ? footerMenus.filter(item => {
        return item.position === 'Column1'
      })
    : null
  const column2Menus = footerMenus
    ? footerMenus.filter(item => {
        return item.position === 'Column2'
      })
    : null
  const column3Menus = footerMenus
    ? footerMenus.filter(item => {
        return item.position === 'Column3'
      })
    : null

  const currentYear = new Date().getFullYear()
  return (
    <footer className="footer">
      <Container>
        <div className="footer-top">
          <Row>
            <Col xs={12} lg={2}>
              <Link to="/" className="footer-logo">
                <svg focusable="false" width="140" height="40">
                  <title>Grocorp</title>
                  <use xlinkHref={`${Icon}#logo`}></use>
                </svg>
              </Link>
            </Col>
            <Col xs={12} lg>
              <Row>
                {column1Menus && column1Menus.length > 0 && (
                  <Col xs={12} md={6} lg>
                    {siteConfig && siteConfig.footerColumn1Title && (
                      <strong className="h5">
                        {siteConfig.footerColumn1Title}
                      </strong>
                    )}
                    <ul>
                      {column1Menus.map((item, idx) => {
                        let target =
                          item.openInNewWindow === false ? '_self' : '_blank'
                        return (
                          <li key={`menu-col1-${idx}`}>
                            <Link to={item.linkURL} target={target}>
                              {item.link.title}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Col>
                )}
                {column2Menus && column2Menus.length > 0 && (
                  <Col xs={12} md={6} lg>
                    {siteConfig && siteConfig.footerColumn2Title && (
                      <strong className="h5">
                        {siteConfig.footerColumn2Title}
                      </strong>
                    )}
                    <ul className="no-title">
                      {column2Menus.map((item, idx) => {
                        let target =
                          item.openInNewWindow === false ? '_self' : '_blank'
                        return (
                          <li key={`menu-col1-${idx}`}>
                            <Link to={item.linkURL} target={target}>
                              {item.link.title}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Col>
                )}
                {column3Menus && column3Menus.length > 0 && (
                  <Col xs={12} md={6} lg>
                    {siteConfig && siteConfig.footerColumn3Title && (
                      <strong className="h5">
                        {siteConfig.footerColumn3Title}
                      </strong>
                    )}
                    <ul>
                      {column3Menus.map((item, idx) => {
                        let target =
                          item.openInNewWindow === false ? '_self' : '_blank'
                        return (
                          <li key={`menu-col3-${idx}`}>
                            <Link to={item.linkURL} target={target}>
                              {item.link.title}
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  </Col>
                )}
              </Row>
            </Col>
            {siteConfig && (
              <Col
                xs={12}
                lg="auto"
                className="d-flex align-items-end justify-content-end"
              >
                <DropdownButton
                  variant="success"
                  className="help-btn"
                  title="Help"
                >
                  <Dropdown.Item href={`tel:${siteConfig.phoneNumber}`}>
                    {siteConfig.phoneNumberText}
                    <svg
                      focusable="false"
                      className="ms-3"
                      width="18"
                      height="18"
                    >
                      <use xlinkHref={`${Icon}#icon-old-phone`}></use>
                    </svg>
                  </Dropdown.Item>
                  <Dropdown.Item href={`mailto:${siteConfig.salesEmail}`}>
                    {siteConfig.salesEmail}
                    <svg
                      focusable="false"
                      className="ms-3"
                      width="18"
                      height="18"
                    >
                      <use xlinkHref={`${Icon}#icon-old-mail`}></use>
                    </svg>
                  </Dropdown.Item>
                  <Dropdown.Item href="https://goo.gl/maps/ELDrR5G5ypK2gJKS8">
                    {siteConfig.address}
                    <svg
                      focusable="false"
                      className="ms-3"
                      width="18"
                      height="16.5"
                    >
                      <use xlinkHref={`${Icon}#icon-old-map`}></use>
                    </svg>
                  </Dropdown.Item>
                  <Dropdown.Item
                    href={`${siteConfig.requestCallbackLink.link}`}
                  >
                    Request Callback
                    <svg
                      focusable="false"
                      className="ms-3"
                      width="18"
                      height="18"
                    >
                      <use xlinkHref={`${Icon}#icon-old-support`}></use>
                    </svg>
                  </Dropdown.Item>
                  <Dropdown.Item href={`${siteConfig.contactUsLink.link}`}>
                    Contact Us
                  </Dropdown.Item>
                </DropdownButton>
              </Col>
            )}
          </Row>
        </div>
        <hr className="divider" />
        <div className="footer-bottom">
          <p>
            Copyright ©{currentYear} <strong>Grocorp</strong>
            <span>
              Website by <a href="https://www.acidgreen.com.au/" target="_blank">acidgreen</a>
            </span>
          </p>
          <img className="payment-icon" src={PaymentIcon} />
        </div>
      </Container>
    </footer>
  )
}

export default Footer
