import React, { useEffect } from 'react'
import Footer from '../Footer'
import Header from '../Header'
import ReactDOMServer from 'react-dom/server'
import parse from 'html-react-parser'
import { graphql, useStaticQuery } from 'gatsby'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Layout = ({ children }) => {
  return (
    <div className="app-layout">
      <Header />
      <main className="app-content">
        {children}
        <ToastContainer />
      </main>
      <Footer />
    </div>
  )
}

export default Layout
