import React, { useMemo, useState } from 'react'
import SmallProduct from '../../images/small-product.png'
import PlaceholderImage from '../../images/recommend.png'
import Icon from '../../images/icons.svg'
import { Col, Modal, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'

const CartQuickView = ({ show, handleClose }) => {
  const cartToken = useSelector(state => state.cart.token)
  const authToken = useSelector(state => state.auth.token)
  const [count, setCount] = useState(1)
  function handlePlus() {
    setCount(count + 1)
  }

  function handleMinus() {
    if (count > 0) {
      setCount(count - 1)
    }
  }

  function handleValueChange(e) {
    e.preventDefault()
    const re = /^[0-9\b]+$/
    if (e.target.value === '' || re.test(e.target.value)) {
      const x = Number(e.target.value)
      setCount(x)
    }
  }

  const cartUrl = useMemo(() => {
    if (authToken)
      return `${process.env.GATSBY_CMS_URL}/headless/sso?token=${authToken}&returnUrl=${process.env.GATSBY_CMS_URL}/checkout/cart`
    if (cartToken)
      return `${process.env.GATSBY_CMS_URL}/headless/cart?token=${cartToken}`
    return null
  }, [authToken, cartToken])

  const checkoutUrl = useMemo(() => {
    if (authToken)
      return `${process.env.GATSBY_CMS_URL}/headless/sso?token=${authToken}&returnUrl=${process.env.GATSBY_CMS_URL}/checkout`
    if (cartToken)
      return `${process.env.GATSBY_CMS_URL}/headless/cart?token=${cartToken}`
    return null
  }, [authToken, cartToken])

  return (
    <Modal
      className="cart-sidebar"
      backdropClassName="cart-backdrop"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title className="subtitle subtitle-md">
          Shopping Cart
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="cart-sidebar-item">
          {/* item */}
          <div className="item">
            <a href="#" className="image">
              <img src={SmallProduct} />
            </a>
            <div className="content">
              <div className="content-top">
                <a href="#" className="h6">
                  Teacher Task Chair Mesh Back Black
                </a>
                <div className="desc">
                  <p className="h6">$349.00</p>
                  <small>Excl. GST: $317.27</small>
                </div>
              </div>
              <div className="content-bottom">
                <div className="quantity-group">
                  <button onClick={handleMinus}>-</button>
                  <input
                    type="number"
                    min="0"
                    value={count}
                    onChange={handleValueChange}
                  />
                  <button onClick={handlePlus}>+</button>
                </div>
                <button className="btn-remove btn-reset">
                  <svg focusable="false" width="16" height="16">
                    <use xlinkHref={`${Icon}#icon-trash`}></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* item */}
          <div className="item">
            <a href="#" className="image">
              <img src={SmallProduct} />
            </a>
            <div className="content">
              <div className="content-top">
                <a href="#" className="h6">
                  Teacher Task Chair Mesh Back Black
                </a>
                <div className="desc">
                  <p className="h6">$349.00</p>
                  <small>Excl. GST: $317.27</small>
                </div>
              </div>
              <div className="content-bottom">
                <div className="quantity-group">
                  <button onClick={handleMinus}>-</button>
                  <input
                    type="number"
                    min="0"
                    value={count}
                    onChange={handleValueChange}
                  />
                  <button onClick={handlePlus}>+</button>
                </div>
                <button className="btn-remove btn-reset">
                  <svg focusable="false" width="16" height="16">
                    <use xlinkHref={`${Icon}#icon-trash`}></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {/* item */}
          <div className="item">
            <a href="#" className="image">
              <img src={SmallProduct} />
            </a>
            <div className="content">
              <div className="content-top">
                <a href="#" className="h6">
                  Teacher Task Chair Mesh Back Black
                </a>
                <div className="desc">
                  <p className="h6">$349.00</p>
                  <small>Excl. GST: $317.27</small>
                </div>
              </div>
              <div className="content-bottom">
                <div className="quantity-group">
                  <button onClick={handleMinus}>-</button>
                  <input
                    type="number"
                    min="0"
                    value={count}
                    onChange={handleValueChange}
                  />
                  <button onClick={handlePlus}>+</button>
                </div>
                <button className="btn-remove btn-reset">
                  <svg focusable="false" width="16" height="16">
                    <use xlinkHref={`${Icon}#icon-trash`}></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="cart-sidebar-recommend">
          <h4>You May Also Like</h4>
          <Row className="cart-sidebar-row gx-3">
            <Col xs={6}>
              <div className="featured-item small">
                <div className="featured-item-inner">
                  <div className="featured-item-image">
                    <img alt="" src={PlaceholderImage} />
                  </div>
                  <p className="h6">Woodmill Hexagonal Shelf Unit</p>
                </div>
                <div className="featured-item-des">
                  <strong className="h6">$499.00</strong>
                  <p className="text-sm">Excl. GST: $1,300.00</p>
                </div>
                <div className="featured-item-button">
                  <a href="#" className="btn btn-outline-primary w-100">
                    Add to Cart
                  </a>
                  <a href="#" className="btn-underline">
                    View Product
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="featured-item small">
                <div className="featured-item-inner">
                  <div className="featured-item-image">
                    <img alt="" src={PlaceholderImage} />
                  </div>
                  <p className="h6">Woodmill Hexagonal Shelf Unit</p>
                </div>
                <div className="featured-item-des">
                  <strong className="h6">$499.00</strong>
                  <p className="text-sm">Excl. GST: $1,300.00</p>
                </div>
                <div className="featured-item-button">
                  <a href="#" className="btn btn-outline-primary w-100">
                    Add to Cart
                  </a>
                  <a href="#" className="btn-underline">
                    View Product
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="featured-item small">
                <div className="featured-item-inner">
                  <div className="featured-item-image">
                    <img alt="" src={PlaceholderImage} />
                  </div>
                  <p className="h6">Woodmill Hexagonal Shelf Unit</p>
                </div>
                <div className="featured-item-des">
                  <strong className="h6">$499.00</strong>
                  <p className="text-sm">Excl. GST: $1,300.00</p>
                </div>
                <div className="featured-item-button">
                  <a href="#" className="btn btn-outline-primary w-100">
                    Add to Cart
                  </a>
                  <a href="#" className="btn-underline">
                    View Product
                  </a>
                </div>
              </div>
            </Col>
            <Col xs={6}>
              <div className="featured-item small">
                <div className="featured-item-inner">
                  <div className="featured-item-image">
                    <img alt="" src={PlaceholderImage} />
                  </div>
                  <p className="h6">Woodmill Hexagonal Shelf Unit</p>
                </div>
                <div className="featured-item-des">
                  <strong className="h6">$499.00</strong>
                  <p className="text-sm">Excl. GST: $1,300.00</p>
                </div>
                <div className="featured-item-button">
                  <a href="#" className="btn btn-outline-primary w-100">
                    Add to Cart
                  </a>
                  <a href="#" className="btn-underline">
                    View Product
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="cart-sidebar-total">
          <p className="h6">Subtotal</p>
          <p className="h6">$1,434.00</p>
        </div>
        <div className="cart-sidebar-button">
          {!!cartUrl && (
            <a href={cartUrl} className="btn btn-outline-primary w-100">
              View Cart
            </a>
          )}
          {!!checkoutUrl && (
            <a href={checkoutUrl} className="btn btn-primary w-100">
              Checkout
            </a>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default CartQuickView
