import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { getValidUrl } from '../../helpers/common'

const CategoryMagento = () => {
  return (
    <StaticQuery
      query={graphql`
        query NavQuery {
          magento {
            categories(pageSize: 300) {
              items {
                uid
                url_path
                name
                include_in_menu
                children {
                  uid
                  url_path
                  name
                  include_in_menu
                  children {
                    uid
                    url_path
                    name
                    include_in_menu
                    children {
                      uid
                      url_path
                      name
                      include_in_menu
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({
        magento: {
          categories: { items: categories },
        },
      }) => {
        const rootCategory = categories ? categories[0] : null
        return (
          <li className="menu-item-has-children">
            <a href="#" className="parent-text">
              Shop by Product
            </a>
            <div className="menu-subs menu-mega pb-0">
              <Container>
                <Row>
                  <Col xs={12} lg={3}>
                    <Row>
                      {!!rootCategory &&
                        rootCategory.children
                          .filter(
                            cat =>
                              cat.include_in_menu === 1 &&
                              cat.name === 'Sectors'
                          )
                          .map((cat, idx) => (
                            <Col key={`root-cat-${idx}`} xs={12}>
                              <Link to={getValidUrl(cat.url_path)}>
                                <strong className="h5">{cat.name}</strong>
                              </Link>
                              <ul className={`inside-list`}>
                                {cat.children
                                  .filter(
                                    subCat => subCat.include_in_menu === 1
                                  )
                                  .map((subCat, idx) => (
                                    <li key={`sub-cat-${idx}`}>
                                      <Link to={getValidUrl(subCat.url_path)}>
                                        {subCat.name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </Col>
                          ))}
                    </Row>
                  </Col>
                  <Col xs={12} lg={9}>
                    <Row>
                      {!!rootCategory &&
                        rootCategory.children
                          .filter(
                            cat =>
                              cat.include_in_menu === 1 &&
                              cat.name === 'Furniture'
                          )
                          .map((cat, idx) => (
                            <Col key={`root-cat-${idx}`} xs={12} lg={3}>
                              <Link to={getValidUrl(cat.url_path)}>
                                <strong className="h5">{cat.name}</strong>
                              </Link>
                              <ul className={`inside-list pb-4 outline-stroke`}>
                                {cat.children
                                  .filter(
                                    subCat => subCat.include_in_menu === 1
                                  )
                                  .map((subCat, idx) => (
                                    <li key={`sub-cat-${idx}`}>
                                      <Link to={getValidUrl(subCat.url_path)}>
                                        {subCat.name}
                                      </Link>
                                    </li>
                                  ))}
                              </ul>
                            </Col>
                          ))}
                      <Col xs={12} lg={9}>
                        <Row>
                          {!!rootCategory &&
                            rootCategory.children
                              .filter(
                                cat =>
                                  cat.include_in_menu === 1 &&
                                  cat.name !== 'Sectors' &&
                                  cat.name !== 'Furniture'
                              )
                              .map((cat, idx) => (
                                <Col key={`root-cat-${idx}`} xs={12} lg={4}>
                                  <Link to={getValidUrl(cat.url_path)}>
                                    <strong className="h5">{cat.name}</strong>
                                  </Link>
                                  <ul className={`inside-list pb-4`}>
                                    {cat.children
                                      .filter(
                                        subCat => subCat.include_in_menu === 1
                                      )
                                      .map((subCat, idx) => (
                                        <li key={`sub-cat-${idx}`}>
                                          <Link
                                            to={getValidUrl(subCat.url_path)}
                                          >
                                            {subCat.name}
                                          </Link>
                                        </li>
                                      ))}
                                  </ul>
                                </Col>
                              ))}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
          </li>
        )
      }}
    />
  )
}

export default CategoryMagento
